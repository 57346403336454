import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import { Redirect, useHistory } from 'react-router-dom';

import FingerprintJS from '@fingerprintjs/fingerprintjs';
import VOTE_ADD from '../../../../graphql/mutations/voteAdd';
import REGISTER_VOTER from '../../../../graphql/mutations/register-voter.mutation';
import useShow from '../../../../hooks/useShow';
import useWelcome from '../../../../hooks/useWelcome';
import { incrementVoteCount } from '../../../../library/voteLimit';
import TermsPage from '../../../Page/terms';
import SuccessfulVote from './SuccessfulVote';
import ErrorModal from './VoteError';


// TODO: this is temporary and should be removed after the event. Just for one client.
// Customizations flags should come from the backend.
function IsCustomClient(showInfo) {
  return [
    'Newfest',
    'Directors\' Fortnight',
  ].includes(showInfo.company_name);
}

const VoteModalFree = ({
  closeModal,
  optionData,
  setIsVoting,
  nextUrl = null,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { currentModule, trackingId, episodeInfo, showInfo } = useShow();
  const { setTermsStorage } = useWelcome();
  const [showTerms, setShowTerms] = useState(false);
  const [fpHash, setFpHash] = useState(null);
  const [location, setLocation] = useState('');
  const [voted, setVoted] = useState(false);
  const [gotData, setGotData] = useState(false);
  const [isError, setIsError] = useState(false);
  const [canShowResults, setCanShowResults] = useState(false);
  const [results, setResults] = useState(false);
  const [createVote, { loading }] = useMutation(VOTE_ADD);
  const [registerVoter] = useMutation(REGISTER_VOTER);
  const [extra, setExtra] = useState({});
  const [form, setForm] = useState({
    values: {
      contact_name: '',
      contact_email: '',
      subscribe_to_newsletter: false,
    },
    errors: {
      contact_name: null,
      contact_email: null,
      subscribe_to_newsletter: null,
    },
    touched: {
      contact_name: false,
      contact_email: false,
      subscribe_to_newsletter: false,
    },
  });

  const escapeJSONString = (str) => str.replace(/[\b\f\n\r\t"\\]/g, (match) => {
    switch (match) {
      case '\b': return '\\b'; // backspace
      case '\f': return '\\f'; // form feed
      case '\n': return '\\n'; // newline
      case '\r': return '\\r'; // carriage return
      case '\t': return '\\t'; // tab
      case '"': return '\\"'; // double quote
      case '\\': return '\\\\'; // backslash
      default: return match;
    }
  });

  const handleVoterInfo = async () => {
    if (!localStorage.getItem('email')) localStorage.setItem('email', form.values.contact_email);
    if (!localStorage.getItem('name')) localStorage.setItem('name', form.values.contact_name);
    if (!localStorage.getItem('extra')) localStorage.setItem('extra', escapeJSONString(JSON.stringify(extra)));

    try {
      const voterPayload = {
        name: localStorage.getItem('name') || form.values.contact_name,
        email: localStorage.getItem('email') || form.values.contact_email,
        extra: localStorage.getItem('extra') || JSON.stringify(extra),
      };

      const result = await registerVoter({
        variables: voterPayload,
      });
      if (result.data.registerVoter) {
        localStorage.setItem('voterId', result.data.registerVoter.id);
      }
      setGotData(true);
    } catch (e) {
      console.error(e, 'error');
    }
  };
  const handleVoteAgainClick = (event) => {
    closeModal(event);
  };
  const handleClickNext = (event) => {
    closeModal(event);
    if (nextUrl) history.replace(nextUrl);
  };

  const setErrorState = (field, condition) => {
    setForm((prevState) => ({
      values: {
        ...prevState.values,
      },
      touched: {
        ...prevState.touched,
      },
      errors: {
        ...prevState.errors,
        [field]: condition ? t('modules.vote.form.required') : false,
      },
    }));
  };
  const handleVoteClick = async (includeContacts) => {
    const { contact_email } = form.values;
    if (includeContacts) {
      const emptyContactEmail = contact_email.length === 0;
      setErrorState('contact_email', emptyContactEmail);

      if (emptyContactEmail) {
        return;
      }
    }
    const voterId = localStorage.getItem('voterId');
    const email = localStorage.getItem('email');
    const name = localStorage.getItem('name');
    const votePayload = {
      question_id: currentModule.id,
      answer_id: optionData.id,
      contact_email: email,
      contact_name: name,
      voter_id: +voterId ? voterId : null,
      weight: 1,
      is_paid: currentModule.is_paid,
      ip_address: fpHash || '127.0.0.1',
      location,
      tracking_id: trackingId,
    };
    try {
      const result = await createVote({
        variables: {
          payload: votePayload,
          // Sending this below, so that I can have a directive in the GraphQL Mutation
          // {@see: createVote mutation}
          showResults: currentModule.show_results,
        },
      });
      if (result.data.createVote.success) {
        setVoted(true);
        incrementVoteCount(currentModule);
        if (episodeInfo.terms_location === 'before_voting') {
          setTermsStorage(true);
        }
        if (
          result.data.createVote.showResults
          && result.data.createVote.results
        ) {
          setCanShowResults(true);
          setResults(result.data.createVote.results);
        }
      }
    } catch (e) {
      setIsError(true);
    }
  };
  

  const handleExtraInfoChange = (field, value) => {
    const newExtra = { ...extra };
    newExtra[field] = escapeJSONString(value);
    setExtra(newExtra);
  };
  const handleFieldChange = (field, event) => {
    const hasRequiredClass = event.target.classList.contains('required');
    const { value } = event.target;
    setForm((prevState) => ({
      values: {
        ...prevState.values,
        [field]: value,
      },
      touched: {
        ...prevState.touched,
        [field]: true,
      },
      errors: {
        ...prevState.errors,
        [field]:
          hasRequiredClass && value.length === 0
            ? t('modules.vote.form.required')
            : false,
      },
    }));
  };

  const handleFieldFocus = () => {
    document.body.classList.add('keyboard-open');
  };

  const handleFieldBlur = (field) => {
    setForm((prevState) => ({
      ...prevState,
      touched: {
        ...prevState.touched,
        [field]: true,
      },
    }));
    document.body.classList.remove('keyboard-open');
  };

  useEffect(() => {
    if (loading) {
      setIsVoting(true);
    } else {
      setIsVoting(false);
    }
  
    // Load FingerprintJS if visitorId (fpHash) isn't set yet
    if (!fpHash) {
      FingerprintJS.load()
        .then((fp) => fp.get())
        .then((result) => {
          setFpHash(result.visitorId);
        })
        .catch((err) => {
          console.error('FingerprintJS Error:', err);
          setFpHash('fingerPrint-error');
        });
    }
  
    // Fetch location if location isn't set yet
    if (!location) {
      fetch('https://pro.ip-api.com/json/?fields=query,country,regionName,city&key=UrDUJRwHtOO6RNP')
        .then((res) => {
          if (!res.ok) {
            throw new Error(`Network response was not ok (${res.status})`);
          }
          return res.json();
        })
        .then(({ query, country, regionName, city }) => {
          const locString = `${query}-${country}-${regionName}-${city}`;
          setLocation(locString);
        })
        .catch((err) => {
          console.error('Location API Error:', err);
          setLocation('location-error');
        });
    }
  }, [loading, fpHash, location, setIsVoting]);  
  

  const renderFreeVoteWithContactsModal = () => (
    <div className={loading ? 'modal-inner loading' : 'modal-inner'}>
      {loading && (
        <div className="modal-spinner">
          <Loader
            type="Grid"
            color="var(--color-primary)"
            height={100}
            width={100}
            style={{
              margin: 'auto auto',
            }}
          />
        </div>
      )}
      <div className="modal__header">
        <div className="modal__title">
          <div className="modal__icon animated bounceIn">
            <i className="fas fa-vote-yea" />
          </div>
        </div>
      </div>
      <div className="modal__content">
        <p>
          {currentModule.contact_cta_text
            || t('modules.vote.form.contacts_title')}
        </p>
        <div className="contacts_container">
          <div className="field field__name">
            {form.errors.contact_name && (
              <span className="has-error">
                {t('modules.vote.form.required')}
              </span>
            )}
            <input
              id="contact_name"
              className="contact_name required"
              placeholder={t('modules.vote.form.name')}
              value={form.values.contact_name}
              onChange={handleFieldChange.bind(null, 'contact_name')}
              onFocus={handleFieldFocus}
              onBlur={handleFieldBlur.bind(null, 'contact_name')}
            />
          </div>
          <div className="field field__email">
            <span>
              {form.errors.contact_email && (
                <span className="has-error">
                  {t('modules.vote.form.required')}
                </span>
              )}
            </span>
            <input
              id="contact_email"
              className="contact_email required"
              placeholder={t('modules.vote.form.email')}
              value={form.values.contact_email}
              onChange={handleFieldChange.bind(null, 'contact_email')}
              onFocus={handleFieldFocus}
              onBlur={handleFieldBlur.bind(null, 'contact_email')}
            />
          </div>
          {showInfo 
            && JSON.parse(showInfo.voter_details) 
            && JSON.parse(showInfo.voter_details).map((voterDetail, index) => {
              const fieldName = `contact_extra_${index}`;
              return (
                <div className="field field__email" key={index}>
                  <span>
                    {form.errors[fieldName] && (
                    <span className="has-error">
                      {t('modules.vote.form.required')}
                    </span>
                    )}
                  </span>
                  <input
                    id={fieldName}
                    className="contact_email required"
                    placeholder={voterDetail}
                    value={extra[voterDetail] || ''}
                    onChange={(e) => handleExtraInfoChange(voterDetail, e.target.value)}
                    onFocus={handleFieldFocus}
                  />
                </div>
              );
            })}
        </div>
      </div>
      <div className="modal__actions">
        {!IsCustomClient(showInfo) && (
        <button
          disabled={loading || IsCustomClient(showInfo)}
          type="button"
          className="actions__secondary"
          onClick={() => handleVoterInfo()}
        >
          {t('modules.vote.form.just_vote')}
        </button>
        )}

        <button
          disabled={loading}
          type="button"
          className="actions__confirm"
          onClick={() => handleVoterInfo(true)}
        >
          {t('modules.vote.form.send')}
        </button>
      </div>
      {episodeInfo.terms_location === 'before_voting' && (
        <div className="modal__terms">
          {`${t('modules.vote.form.by_voting_accept_terms')} `}
          <button
            type="button"
            className="btn-link"
            onClick={() => setShowTerms(true)}
          >
            {t('welcome.terms_link')}
          </button>
        </div>
      )}
    </div>
  );

  const renderSuccessfulVoteModal = () => {
    if (canShowResults && results) {
      return (
        <Redirect
          to={{
            pathname: `/${currentModule.id}`,
            state: {
              view: 'results',
              results,
              currentModule,
              fetchVotes: true,
            },
          }}
        />
      );
    }
    return (
      <SuccessfulVote
        onClickNext={handleClickNext}
        handleVoteAgainClick={handleVoteAgainClick}
      />
    );
  };

  if (isError) {
    return (
      <div className="modal">
        <ErrorModal handleVoteAgainClick={handleVoteAgainClick} />
      </div>
    );
  }
  return (
    <>
      <div className="modal">
        {showTerms ? (
          <TermsPage isModal overrideBack setShowTerms={setShowTerms} />
        ) : voted ? (
          renderSuccessfulVoteModal()
        ) : !gotData
          && currentModule.collect_contact_details
          && (
            !localStorage.getItem('voterId')
            || !localStorage.getItem('name')
            || !localStorage.getItem('email')
          ) ? (
          // 3) If we still need to collect contact details, show the free-vote form
            renderFreeVoteWithContactsModal()
          ) : fpHash && location ? (
          // 4) If we have fingerprint + IP, vote now, then set state and return null
            (handleVoteClick(), setVoted(true), null)
          ) : (
          // 5) Otherwise, still fetching fingerprint & location, show spinner
            <Loader
              type="Grid"
              color="var(--color-primary)"
              height={100}
              width={100}
              style={{
                margin: 'auto auto',
              }}
            />
          )}
      </div>
    </>
  );
  
};

export default VoteModalFree;
